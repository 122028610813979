@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Inter, sans-serif;
}

.bg-public-layout {
  background-color: #F9FBFD;
}

.selected-header-menu span {
  background-color: #FFF !important;
  border-color: #FFF;
  height: 32px;
  border-radius: 2px;
  color: #09090B !important;
  display: flex;
  align-items: center;
}

.header-menu {
  height: 40px;
}

.header-menu span {
  font-size: 15px !important;
  color: #FAFAFA;
  padding: 0px 15px;
}

.admin-button,
.admin-button:hover {
  color: #FFF;
  height: 32px;
}

.topic-button,
.topic-button:hover {
  background-color: #FFF !important;
  border-color: #FFF;
  height: 45px;
  min-width: 250px;
  color: #09090B !important;
}

.configuration-button,
.configuration-button:hover {
  background-color: #FFFFFF !important;
  border-color: #E4E4E7;
  height: 40px;
  min-width: 250px;
  border-radius: 8px;
  color: #09090B !important;
}

.dashboard-button,
.dashboard-button:hover {
  background-color: #FFFFFF !important;
  border-color: #E4E4E7;
  height: 40px;
  min-width: 130px;
  border-radius: 8px;
  color: #09090B !important;
}

.configuration-button-prompt-small,
.configuration-button-prompt-small:hover {
  background-color: #FFFFFF !important;
  border-color: #E4E4E7;
  height: 40px;
  min-width: 80px;
  border-radius: 8px;
  color: #09090B !important;
}

.configuration-button-prompt-large,
.configuration-button-prompt-large:hover {
  background-color: #FFFFFF !important;
  border-color: #E4E4E7;
  height: 40px;
  min-width: 50%;
  border-radius: 8px;
  color: #09090B !important;
}

.export-button,
.export-button:hover {
  background-color: #18181B !important;
  border: none !important;
  height: 40px;
  color: #FAFAFA !important
}

.export-button:disabled {
  background-color: rgba(24, 24, 27, 0.4) !important;
  height: 40px;
  color: #FAFAFA !important
}

.view-button,
.view-button:hover {
  background-color: #FFFFFF !important;
  border-color: #E4E4E7 !important;
  height: 32px;
  color: #09090B !important;
  font-size: 12px !important;
}

.reject-button,
.reject:hover {
  background-color: #EF4444 !important;
  border-color: #EF4444 !important;
  height: 40px;
  color: #F8FAFC !important;
  font-size: 14px !important;
  font-weight: 500;
}

.reject:disabled {
  height: 40px;
  color: #F8FAFC !important;
  font-size: 14px !important;
  font-weight: 500;
}

.confirm-button,
.confirm-button:hover {
  background-color: #C4950C !important;
  border-color: #C4950C !important;
  height: 40px;
  width: 100%;
  color: #F8FAFC !important;
  font-size: 14px !important;
  font-weight: 500;
}

.confirm-button:disabled {
  height: 40px;
  width: 100%;
  color: #F8FAFC !important;
  font-size: 14px !important;
  font-weight: 500;
}

.ant-segmented-item-selected {
  height: 32px;
}

.ant-segmented-item-label {
  height: 32px;
  font-weight: 500;
  line-height: 32px !important;
  font-size: 14px !important;
}

.ant-segmented .ant-segmented-item:not(:last-child) {
  border-radius: 4px !important;
  margin: 0px 10px 0px 0px
}

.ant-segmented .ant-segmented-item:last-child {
  border-radius: 4px !important;
  min-width: 95px;
  margin: 0px
}

.admin-tab-container {
  height: 36px;
  display: flex;
  border-radius: 6px;
  align-items: center;
  margin: 2px 0px;
  cursor: pointer;
}

.selected-admin-tab-container {
  background-color: #F4F4F5;
  padding: 8px 16px;
}

.ant-table-wrapper {
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 8px;
}

.ant-table {
  border-radius: 8px !important;
}

.ant-table-cell.th {
  border-top-right-radius: 8px;
}

.role-tag-container {
  font-size: 12px;
  font-weight: 600;
  color: #FFF;
  border-radius: 20px;
  padding: 1px 10px;
  cursor: pointer;
}

.results-tag-container {
  font-size: 12px;
  font-weight: 600;
  color: #020617;
  border-radius: 9999px;
  padding: 1px 10px;
  border: 1px solid #E2E8F0;
  cursor: pointer;
  background-color: #FFF;
}

.admin {
  background-color: #DC2626;
}

.unselected-editor {
  color: #DC2626;
  background-color: transparent;
  border: 1px solid #DC2626;
}

.viewer,
.contributor {
  background-color: #091D3F;
}

.unselected-viewer,
.unselected-contributor {
  color: #091D3F;
  background-color: transparent;
  border: 1px solid #091D3F;
}

.editor {
  background-color: #C4950C;
}

.unselected-editor {
  color: #C4950C;
  background-color: transparent;
  border: 1px solid #C4950C;
}

.ant-dropdown-menu-item-selected {
  background-color: #F1F5F9 !important;
  border-radius: 4px !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #F1F5F9 !important;
  border-radius: 4px !important;
}

.page-size-button {
  border-radius: 6px;
  width: 75px;
}

.page-icon-button {
  width: 32px;
  height: 32px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-icon {
  color: #09090B;
  font-size: 14px;
}

.page-icon-button:disabled {
  background-color: transparent;
}

.page-icon-button:not(:last-child) {
  margin-right: 8px;
}

.signout-button,
.signout-button:hover {
  background-color: #C4950C !important;
  border-radius: 6px;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected>.ant-table-cell {
  background: transparent !important;
}

.golden-tag {
  border: none;
  background-color: #C4950C;
  color: #F8FAFC;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 9999px;
}

.golden-tag-disabled {
  border: none;
  background-color: gray;
  color: #F8FAFC;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 9999px;
}

.blue-tag {
  border: none;
  background-color: #091D3F;
  color: #F8FAFC;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 9999px;
}

.blue-tag-geolocation {
  border: none;
  height: 40px;
  background-color: #091D3F;
  color: #F8FAFC;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 10px;
  border-radius: 8px;
}

.keyboard-tag {
  font-size: 12px;
  font-weight: 600;
  color: #020617;
  border-radius: 6px;
  padding: 2px 10px;
  background-color: #FFF;
  border: 1px solid #E2E8F0;
}

.ai-sandbox-container {
  height: 80px;
  border-radius: 8px;
  border: 1px solid #E2E8F0;
}

.ai-sandbox-containerPrompt {
  border-radius: 8px;
  border: 1px solid #E2E8F0;
}

.statusCard {
  height: 175px;
  width: 224px;
  border-radius: 8px;
  border: 1px solid #E2E8F0;
}

.box-title {
  background: white;
  position: absolute;
  top: -11%;
  left: 4%;
  padding: 0px 10px;
}

.box-title-prompt {
  background: white;
  position: absolute;
  top: -6%;
  left: 4%;
  padding: 0px 10px;
}

.prompt-container {
  background-color: #F1F5F980;
  width: 100%;
}

.ouput-container {
  height: 350px;
  overflow-y: scroll;
}

.text-area-container {
  width: 100%;
  height: 239px;
  border-radius: 8px;
  background-color: #FFF;
  border: 1px solid #E2E8F0;
}

.text-area-container textarea {
  border: none
}

.activity-container {
  width: 100%;
  height: 114px;
  border-radius: 8px;
  background-color: #FFF;
  border: 1px solid #E2E8F0;
}

.activity-container textarea {
  border: none
}

.delete-action,
.delete-action:hover {
  border: none !important;
  background-color: #DC2626 !important;
  color: #FFFFFF !important;
  margin-left: 10px;
}

.delete-action:disabled {
  opacity: 0.5;
}

.status-vertical-divider {
  border: 0.1px solid #64748B;
  height: 20px;
}

.small {
  width: 5%;
  height: 40px;
  background-color: #94A3B8;
  border: 0.5px solid #FFFFFF;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.small-text {
  width: 5%;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.medium {
  width: 8%;
  height: 40px;
  background-color: #94A3B8;
  border: 0.5px solid #FFFFFF;
}

.medium-text {
  width: 13%;
  font-size: 14px;
  text-align: end;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.large {
  width: 15%;
  height: 40px;
  background-color: #94A3B8;
  border: 0.5px solid #FFFFFF;
}

.large-text {
  width: 15%;
  font-size: 14px;
  text-align: end;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.x-large {
  width: 22%;
  height: 40px;
  background-color: #94A3B8;
  border: 0.5px solid #FFFFFF;
}

.x-large-text {
  width: 22%;
  font-size: 14px;
  text-align: end;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.xx-large {
  width: 50%;
  height: 40px;
  background-color: #94A3B8;
  border: 0.5px solid #FFFFFF;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.xx-large-text {
  width: 50%;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.selected {
  background-color: #091D3F;
  justify-content: center;
  align-items: center;
  font-family: 400;
  color: #FFFFFF;
  font-size: 14px;
  display: flex;
}

.ant-select-dropdown .ant-select-item-option-selected {
  background-color: #F1F5F9 !important;
  border-radius: 6px;
}

.country-dropdown {
  height: 32px;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  padding: 0px 12px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  border: 1px dashed #E4E4E7;
}

.country-dropdown-overlay {
  height: 300px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 3px 4px 4px -2px #0000001A !important;
}

.switch-true {
  background-color: #0F172A !important;
}

.switch-false {
  background-color: #E2E8F0 !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-form-item .ant-form-item-label>label {
  font-size: 14px;
  font-weight: 500;
}

.ant-input {
  color: #71717A;
  font-weight: 400;
}

.ant-table-body {
  overflow: auto !important;
}

.ant-modal .ant-modal-content {
  border-radius: 6px !important;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background-color: transparent;
}

.ant-table-wrapper td.ant-table-column-sort {
  background-color: transparent;
}

.dashboard-border {
  height: 100%;
  border-radius: 8px;
  border: 1px solid #E2E8F0;
}


/* ***********************chat************************** */

.chat-box {
  position: absolute;
  bottom: 0px;
  right: 2rem;
  width: 423px;
  height: 80px;
  background: #FFFFFF;
  border-radius: 18px;
  z-index: 1000;
  box-shadow: 5px 14px 80px 0px #1A1A1A1F;
  box-shadow: 10px 10px 40px 0px #00000014;
}

.msg-header {
  border-radius: 18px 18px 0px 0px;
  width: 100%;
  height: 80px;
  background: #091D3F;
}

.msg-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.chat-content {
  height: 325px;
  overflow-y: scroll;
}

.chat-box.show {
  animation: 0.5s slide-up;
  height: 600px;
}

@keyframes slide-up {
  from {
    height: 80px;
  }
  to {
    height: 600px;
  }
}

.ant-select-item-option-content {
  text-transform: capitalize;
}

.rce-mbox {
  width: 90% !important;
}